module controllers {
    export module master {
        interface IProductTypeScope extends ng.IScope {
        }
        interface IProductTypeParams extends ng.ui.IStateParamsService {
        }

        export class productTypeController {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', "$stateParams",
                "productTypeService", "entityService",
            ];

            productList: Array<interfaces.tariff.IProductTypeDisplay> = [];
            apiProductList: uiGrid.IGridApi;
            entity: interfaces.applicationcore.IDropdownModel;
            entityId: number = undefined;
            IsLoading: boolean = true;
            showAll: boolean = true;

            productTypeSearch: interfaces.master.IProductTypeSearch = {
            };

            constructor(private $scope: IProductTypeScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: IProductTypeParams,
                private productTypeService: interfaces.master.IProductTypeService,
                private entityService: interfaces.applicationcore.IEntityService
            ) {
                this.loadProducts();
            }

            loadProducts(productDescription: string = "", productCode: string = "" ) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prod.type'
                }, () => {
                    //Loads Product Types
                    return this.productTypeService.getList().query({
                        entityId: this.entityId,
                        description: productDescription,
                        typeCode: productCode,
                        showAll: this.showAll,
                    }, (result: Array<interfaces.tariff.IProductTypeDisplay>) => {
                        this.productList = result;

                        this.$timeout(() => {
                            this.gvwProductList.data = result;
                        });
                          this.IsLoading = false;
                    }, (errorResponse) => {
                          this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                          this.IsLoading = false;
                    }).$promise;
                });
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            addNewType() {
                var add = <interfaces.tariff.IProductTypeDisplay>{
                    Entity: <interfaces.applicationcore.IDropdownModel>{
                        Id: 0,
                        Code: "",
                        Description: "",
                        Display: "",
                        Selected: true
                    },
                    Code: "",
                    Description: "",
                    IsDefault: false,
                    IsActive: true,
                };
                (<Array<any>>(this.gvwProductList.data)).splice(0, 0, add);
            };

            registerProductGridApi(gridApi: uiGrid.IGridApi) {
                this.apiProductList = gridApi;

                this.apiProductList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiProductList.selection.selectRow(gridSelection.row.entity);
                });

                this.$timeout(() => {
                    this.gvwProductList.data = this.productList;
                });
            };

            gvwProductList: uiGrid.IGridOptions = {
                data: <Array<interfaces.tariff.IProductTypeDisplay>>[],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerProductGridApi(gridApi); },
                columnDefs: [{
                    name: "REMOVE",
                    displayName: "",
                    field: "ProductTypeRemove",
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-click="grid.appScope.productTypeController.removeInlineProductType(row.entity)" class="btn btn-warning btn-sm">
                            Delete
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    enableCellEditOnFocus: false,
                    width: 55,
                    headerCellTemplate: `
                        <div class="GridButton">
                            <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.productTypeController.addNewType()">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                }, {
                    name: "ENTITY",
                    displayName: "* Entity",
                    field: "Entity",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Description}}">{{MODEL_COL_FIELD.Description}}</div>`,
                    width: 80,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTypeController.loadEntities(searchText)" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                            </form>`,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                }, {
                    name: "CODE",
                    displayName: "* Code",
                    field: "Code",
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    width: 100,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    type: "string",
                    editableCellTemplate: `
                            <form name = "inputForm">
                                <p class="input-group-sm">
                                    <input type="text" name="Code-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor step = "0.01" />
                                </p>
                            </form>`,
                }, {
                    name: "DESCRIPTION",
                    displayName: "* Description",
                    field: "Description",
                    width: 200,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    type: "string",
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="Description-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor step = "0.01" />
                            </p>
                        </form>`,
                }, {
                    name: "DEFAULT",
                    displayName: "Default",
                    field: "IsDefault",
                    width: 70,
                    cellTemplate: `<center><input type="checkbox" ng-change="grid.appScope.productTypeController.makeRowDirty(row)" ng-model="row.entity.IsDefault"></center>`,
                    editableCellTemplate: ` 
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="row.entity.IsDefault">
                                </p>
                            </form>`,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                }, {
                    name: "ACTIVE",
                    displayName: "* Active",
                    field: "IsActive",
                    width: 70,
                    cellTemplate: `<center><input type="checkbox" ng-change="grid.appScope.productTypeController.makeRowDirty(row)" ng-model="row.entity.IsActive"></center>`,
                    editableCellTemplate: ` 
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="row.entity.IsActive">
                                </p>
                            </form>`,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                }, {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                            </gts-stamp-info>
                        </div>`,
                    width: 38,
                    enableSorting: false,
                },]
            };

            makeRowDirty(row: uiGrid.IGridRow) {
                this.apiProductList.rowEdit.setRowsDirty([row.entity]);
            }

            entityChange(model: interfaces.applicationcore.IDropdownModel) {
                this.entity = model;

                if (model)
                    this.entityId = model.Id;
                else
                    this.entityId = undefined;
            }
            
            removeInlineProductType(entity: interfaces.tariff.IProductTypeDisplay) {
                if (entity.Id > 0)
                {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'prod.type'
                    }, () => {
                        return this.productTypeService.removeProductType().delete({
                            prodId: entity.Id,
                        }, (result) => {
                            this.$timeout(() => {
                                this.loadProducts();
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
                else
                {
                    _.remove(this.gvwProductList.data, (o) => {
                        return o === entity;
                    });
                }
            }
            
            //Submit
            submitType() {
                //Get dirty products.
                var productList: Array<interfaces.tariff.IProductTypeDisplay> = [];
                if (this.apiProductList && this.apiProductList.rowEdit.getDirtyRows().length > 0) {
                    productList = _.map(this.apiProductList.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'prod.type'
                }, () => {
                    //Save Dirty Products
                    return this.productTypeService.SaveProductTypes().save(productList, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        this.$timeout(() => {
                            if (this.apiProductList) {
                                this.apiProductList.rowEdit.setRowsClean(productList);
                                this.loadProducts();
                            }
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            };

            Close() {
                this.entity = undefined;
                this.entityId = undefined;
                this.$anchorScroll("topAnchor");
                this.$state.go("^");
            }
        }

        angular.module("app").controller("productTypeController", controllers.master.productTypeController);
    }
}